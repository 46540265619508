import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Accordion from "../components/Accordion"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  WrapperLight,
  WrapperDarkGreen,
  Content,
} from "../components/section-styling"
import { ButtonLink } from "../components/ButtonLink"

export default function IndexPage({ data }) {
  return (
    <Layout>
      <Seo title="Welcome" />

      <WrapperLight>
        <Content>
          <StaticImage
            src="../images/sassy-plant-05.png"
            className="large-image"
            height={1000}
            alt="Two plants beside each other."
          />
          <div className="alignRight">
            <h1>Welcome to Sassafras.</h1>
            <p>
              We're a unionized worker co-op that designs and builds technology
              for justice and joy.
            </p>
          </div>
        </Content>
      </WrapperLight>

      <WrapperDarkGreen>
        <Content>
          <div>
            <h1>We have what you need.</h1>

            <Accordion
              title="Do you run a website, send emails, or store files?
              You're probably funding harmful corporations. We can help you divest."
            ></Accordion>
            <Accordion
              title="Is your website mobile-friendly AND accessible to disabled users?
              How do you know? We can fix it."
            ></Accordion>
            <Accordion
              title="Are you starting a new website or mobile app? We can get you on
              the right path."
            ></Accordion>
            <Accordion
              title="Do you need a rebrand? We make the whole process
              simple."
            ></Accordion>

            <br />
            <ButtonLink to="/services" className="dark">
              SERVICES
            </ButtonLink>
            <ButtonLink to="/contact" className="dark">
              TALK TO US
            </ButtonLink>
          </div>
        </Content>
      </WrapperDarkGreen>

      <WrapperDarkGreen>
        <Content>
          <div>
            <h1>Movement-aligned work with meaningful impact.</h1>
            <p>
              As a unionized cooperative, we the workers run the business
              &ndash; and that's our greatest strength.
            </p>

            <p>
              Our worker-owners design and build technology in service of
              justice movements. Our software projects expand spaces for
              movements and the people in them to dream, collaborate, build
              power, heal, and experience joy.
            </p>

            <p>
              We know that <i>people</i> solve problems, not computers. We may
              advise against custom software if you and your organization can
              meet your needs another way that is more sustainable or safer. If
              we develop software together, we will help you prioritize the
              expertise, needs, and safety of the people who will use it.
            </p>

            <p>
              Our projects span from non-extractive finance software to digital
              art tapestries; from online storytelling for anti-harassment to
              data collection apps for equitable health research.
            </p>

            <ButtonLink to="/ourwork" className="dark">
              OUR WORK
            </ButtonLink>
            <ButtonLink to="/contact" className="dark">
              TALK TO US
            </ButtonLink>
          </div>
        </Content>
      </WrapperDarkGreen>

      <WrapperLight>
        <Content className="alignRight">
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column nowrap",
                justifyContent: "space-between",
                textAlign: "right",
                alignItems: "center",
                gap: "5px",
                paddingBottom: "1.08rem",
              }}
            >
              <StaticImage
                className="small-image"
                src="../images/sassy-plant-14.png"
                width={200}
                layout="fixed"
                alt=""
              />
              <h1>Connect with us.</h1>
            </div>
            <p>
              Follow us on{" "}
              <a
                href="https://twitter.com/sassafrastech/"
                target="_blank"
                rel="noopener noreferrer"
              >
                social media
              </a>{" "}
              and keep up with us on our <a href="/blog">blog</a>. There you'll
              find resources for fellow co-ops as well as the latest stories on
              our design and development work.
            </p>

            <hr />

            <div>
              <Accordion
                title={parse(data.allWpPost.nodes[0].title)}
                date={data.allWpPost.nodes[0].date}
                text={parse(data.allWpPost.nodes[0].excerpt)}
              />

              <ButtonLink
                to={"/blog/" + data.allWpPost.nodes[0].slug}
                className="light"
              >
                READ MORE
              </ButtonLink>
              <ButtonLink to="/blog" className="light">
                SEE ALL
              </ButtonLink>
            </div>
          </div>
        </Content>
      </WrapperLight>
    </Layout>
  )
}

// Query for most recent blog post
export const blogPostQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }, limit: 1) {
      nodes {
        title
        date(formatString: "MMMM DD, YYYY")
        excerpt
        slug
      }
    }
  }
`
